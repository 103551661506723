export function data() {
  return {
    formData: {
      waiverRequestID: 0,
      amount: 0,
      reason: '',
      addedBy: '',
      status: ''
    },
    isDebug: true,
    isOpen: false,
    arTransactionLimitAmount: 0,
    summedExistingWaiverRequestAmounts: 0,
    loading: true,
    openOnFocus: true,
    showWeekNumber: false,
    buttonText: 'Add',
    styles: {
      datePickerContainer: { display: 'flex', alignItems: 'end' },
      datePicker: { margin: 0, marginRight: '12px' }
    }
  }
}
