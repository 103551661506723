/*
  import { suspensionStore } from '@/services/Units/Tabs/WaiverFeeRequests/WaiverModal/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async getArTransactionById({}, id) {
    try {
      const result = await kms.get(`/AccountsReceivable/ARTransaction/${id}`)

      console.debug('result=' + JSON.stringify(result))

      return {
        detail: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the A/R transaction for the waiver request.`)
      console.error(exception)
    }
    return {
      detail: null
    }
  },

  // Update Waiver
  async updateWaiver({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/AccountsReceivable/WaiverRequest`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.waiverRequestID > 0) {
        notifyMessage(`Successfully updated the selected waiver request.`)
        done({ details: results })
      } else {
        notifyError('There was a problem updating the selected waiver request.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this waiver request.`)
      console.error(exception)
    }
  },

  async addWaiver(
    { dispatch },
    { ownerID, reason, amount, arTransactionID, createdByAspNetUserID, status, done }
  ) {
    if (isDebug == true) console.debug('...in addSuspension')
    try {
      const results = await kms.post(`/AccountsReceivable/WaiverRequest`, {
        ownerID,
        reason,
        amount,
        arTransactionID,
        createdByAspNetUserID,
        status
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.waiverRequestID > 0) {
        notifyMessage(`Successfully added the waiver request.`)
        done({ details: results })
      } else {
        notifyError('There was a problem adding this waiver request.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this waiver request.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const waiverStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
