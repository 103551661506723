/*
  import { waiverList } from '@/services/Units/Tabs/WaiverFeeRequests/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'

import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await waiverList.dispatch ('getWaiverList', {
      hoaId
    });
  */
  async getWaiverList({}, { ownerID }) {
    console.log('in getWaiverList...')
    try {
      const result = await kms.get('/AccountsReceivable/WaiverRequest/List', {
        params: {
          ownerID
        }
      })

      if (isDebug == true) console.debug('getWaiverList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The waiver request list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async deleteWaiver({ dispatch }, { waiverRequestID, done }) {
    try {
      const path = `/AccountsReceivable/WaiverRequest/${waiverRequestID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the selected waiver request.`)
        done()
      } else {
        notifyError('There was a problem deleting the selected waiver request.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting the selected waiver request.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const waiverList = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
