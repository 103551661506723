import { waiverStore } from '@/services/Units/Tabs/WaiverFeeRequests/WaiverModal/store'
import $ from 'jquery'
import { notifyError } from '@/services/notify'
import _get from 'lodash/get'

export const methods = {
  async reload() {
    this.loading = true

    if (this.waiver && this.waiver != undefined) {
      let myStatus = this.waiver.status ? this.waiver.status : ''
      if (this.waiver.rejectionReason && myStatus == 'Rejected') {
        myStatus = `${myStatus} - ${this.waiver.rejectionReason}`
      }

      //Update
      this.buttonText = 'Update'
      this.formData.waiverRequestID = this.waiver.waiverRequestID
      this.formData.amount = Number.isInteger(this.waiver.amount || 0)
        ? this.waiver.amount + '.00'
        : this.waiver.amount || 0
      this.formData.reason = this.waiver.reason
      this.formData.addedBy =
        this.waiver.createdByIdentityInformation &&
        this.waiver.createdByIdentityInformation != undefined
          ? this.waiver.createdByIdentityInformation.name
          : null
      this.formData.status = myStatus

      const arTransId = _get(this.waiver, 'arTransactionID', 0)
      await this.getArTransactionLimit(arTransId)
      this.loading = false
    } else {
      this.buttonText = 'Add'
      await this.getArTransactionLimit(this.arTransactionID)
    }

    this.loading = false
  },

  open() {
    this.formData = {
      amount: 0,
      addedBy: '',
      reason: ''
    }
    this.isOpen = true
    this.loading = false
  },

  async getArTransactionLimit(id) {
    if (this.isDebug == true) console.debug('getArTransactionLimit id=' + id)

    this.arTransactionLimitAmount = 0
    this.summedExistingWaiverRequestAmounts = 0

    if (id && id > 0) {
      await waiverStore.dispatch('getArTransactionById', id).then(t => {
        if (t && t.detail) {
          if (t.detail.waiverRequests && t.detail.waiverRequests != undefined) {
            //sum each waiver request amount
            this.summedExistingWaiverRequestAmounts = t.detail.waiverRequests.reduce(
              (acc, item) => acc + item.amount,
              0
            )
          }
          this.arTransactionLimitAmount = t.detail.amount
          if (this.isDebug == true)
            console.debug('arTransactionLimitAmount=' + this.arTransactionLimitAmount)
        }
      })
    }
  },

  async submitWaiver() {
    let isUpdate = false

    if (this.formData && !this.formData.amount) {
      notifyError(
        'Please enter an amount. The amount is a required field that must be greater than zero.'
      )
      return
    }

    if (
      this.formData.amount &&
      this.formData.amount != undefined &&
      this.summedExistingWaiverRequestAmounts
    ) {
      if (this.formData.waiverRequestID && this.formData.waiverRequestID > 0) {
        isUpdate = true
      }

      //edit sums existing plus new entry, so edit needs to account for previous amount
      const calculateFormAdd =
        isUpdate && this.waiver
          ? Number.parseFloat(this.formData.amount) - Number.parseFloat(this.waiver.amount)
          : this.formData.amount

      const summedPlusFormAmount =
        Number.parseFloat(this.summedExistingWaiverRequestAmounts) +
        Number.parseFloat(calculateFormAdd)
      if (summedPlusFormAmount > this.arTransactionLimitAmount) {
        notifyError(
          `Please enter a waiver amount that is less than the A/R transaction amount of $${this.arTransactionLimitAmount}. This transaction currently has a summed total amount of $${this.summedExistingWaiverRequestAmounts}. You entered $${this.formData.amount}, which would make the total waiver requests $${summedPlusFormAmount}. `
        )
        return
      }
    }

    if (this.formData && this.formData != undefined) {
      if (isUpdate) {
        await this.updateWaiver()
      } else {
        await this.addWaiver()
      }
    }
  },

  async addWaiver() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem processing this waiver fee request.')
      return
    }

    if (this.formData.amount <= 0) {
      notifyError(`The new amount must be greater than zero.`)
      return
    }

    if (!this.arTransactionID && this.arTransactionID <= 0) {
      notifyError(`This request must be associated with an A/R transaction.`)
      return
    }

    this.loading = true

    if (this.isDebug == true) console.debug('addWaiver params=' + JSON.stringify(this.formData))

    await waiverStore.dispatch('addWaiver', {
      ownerID: this.ownerID,
      reason: _get(this.formData, 'reason', ''),
      amount: _get(this.formData, 'amount', ''),
      arTransactionID: this.arTransactionID,
      createdByAspNetUserID: 'Stub',
      status: 'Pending',
      done: ({ details }) => {
        this.loading = false
        if (details && details != undefined) {
          if (this.isDebug == true)
            console.debug(
              'details.waiverRequestID=' +
                JSON.stringify(details) +
                ', waiverRequestID=' +
                details.waiverRequestID
            )

          this.leaveForm()
        }
      }
    })

    this.loading = false
  },

  async updateWaiver() {
    if (
      !this.formData ||
      this.formData == undefined ||
      !this.formData.waiverRequestID ||
      this.formData.waiverRequestID == undefined ||
      !this.waiver ||
      this.waiver == undefined
    ) {
      notifyError('There was a problem updating the selected waiver request.')
      return
    }

    if (this.formData.amount <= 0) {
      notifyError(`The new amount must be greater than zero.`)
      return
    }

    this.loading = true

    const payload = {
      waiverRequestID: _get(this.formData, 'waiverRequestID', 0),
      ownerID: _get(this.waiver, 'ownerID', 0),
      reason: _get(this.formData, 'reason', ''),
      amount: _get(this.formData, 'amount', 0),
      status: _get(this.waiver, 'status', ''),
      arTransactionID: _get(this.waiver, 'arTransactionID', 0),
      createdByAspNetUserID: 'Stub'
    }

    await waiverStore.dispatch('updateWaiver', {
      payload: payload,
      done: ({ details }) => {
        this.loading = false
        if (details) {
          this.leaveForm()
        }
      }
    })

    this.loading = false
  },

  leaveForm() {
    // auto-close modal
    this.$emit('update:toggle', (this.toggle = false))
    $('#clickclose').click()
    this.loading = false
  }
}
