<template>
  <div>
    <button class="w-5 h-5 ml-0" @click="loadUpdateWaiver(waiver)">
      <svg
        class="w-6 h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
        ></path>
      </svg>
    </button>
    <button class="w-5 h-5 ml-4" @click="confirmWaiverDelete(reason, waiverRequestID)">
      <svg
        class="w-6 h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    loadUpdateWaiver: Function,
    confirmWaiverDelete: Function,
    reason: String,
    waiver: Object,
    waiverRequestID: [Number, String]
  }
}
</script>
