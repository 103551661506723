<template>
  <PageContentLayoutOnly :aria-label="$t('waiver.title')" role="region">
    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
      headerColor="#FFF"
      condensed
    >
      <template v-slot:header>
        <div :style="{ width: '100%', height: '60px' }">
          <b-button
            type="is-ghost"
            @click="filters.show = !filters.show"
            :style="{ float: 'right', background: 'none', border: 0 }"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
      </template>
    </ModernTable>
    <Modal :toggle.sync="toggle">
      <WaiverModal
        :waiver="selectedWaiver"
        :ownerID="parseInt(ownerID)"
        :arTransactionID="parseInt(arTransactionID)"
      />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import _get from 'lodash/get'
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import { parseDate } from '@/utilities/Date/parse'
import { waiverList } from '@/services/Units/Tabs/WaiverFeeRequests/store'
import WaiverActions from './component/WaiverActions'
import WaiverModal from './component/WaiverModal'
import Modal from '@/components/Modal'

export default {
  name: 'WaiverFeeRequest',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    Modal,
    WaiverModal
  },

  data: () => ({
    isDebug: true,
    loading: true,
    selectedWaiver: null,
    toggle: false,
    unitID: 0,
    arTransactionID: 0,
    radio: 'active',

    styles: {
      dateSelector: {
        display: 'flex',
        padding: '12px',
        textAlign: 'center',
        verticalAlign: 'middle',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-end'
      },
      filterButton: {
        float: 'right',
        marginTop: '4px'
      }
    },

    region: {},
    filters: {
      show: false
    },
    rows: [],
    columns: [
      {
        field: 'transactionChargeType',
        label: 'Charge Type',
        aria: 'Charge Type',
        width: '12%',
        sortable: true,
        searchable: true
      },
      {
        field: 'amount',
        label: 'Fee Amount',
        aria: 'Fee Amount',
        width: '12%',
        sortable: true,
        searchable: true
      },
      {
        field: 'reason',
        label: 'Reason',
        aria: 'Reason',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'createdBy',
        label: 'Created By',
        aria: 'Created By',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'createdDate',
        label: 'Created Date',
        aria: 'Created Date',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'status',
        label: 'Status',
        aria: 'Status',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'postedBy',
        label: 'Posted By',
        aria: 'Posted By',
        width: '12%',
        sortable: true,
        searchable: true
      },
      {
        field: 'actions',
        label: 'Actions',
        width: '5%'
      }
    ]
  }),

  created() {
    if (this.isDebug == true) console.debug('authUser=' + JSON.stringify(this.authUser))
    this.determineOwnerID()
  },

  methods: {
    determineOwnerID() {
      this.ownerID = 0

      if (this.authUser && this.authUser != undefined) {
        this.ownerID = _get(this, '$store.state.hoa.unit.primaryOwner.ownerID', null)
      }

      const fromOwnerID = _get(this.$route.query, 'fromOwnerID', 0)
      if (fromOwnerID > 0) {
        this.ownerID = fromOwnerID
        if (this.isDebug == true) console.debug('this.fromOwnerID=' + fromOwnerID)
      }

      if (this.isDebug == true) console.debug('this.ownerID=' + this.ownerID)
    },
    determineRows: function(list) {
      if (!Array.isArray(list)) {
        return
      }

      if (this.isDebug == true) console.debug('determineRows list=' + JSON.stringify(list))

      const dateFormat = 'LL'

      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })

      this.rows = []
      var rows = list.map(entry => {
        const waiverRequestID = _get(entry, 'waiverRequestID', '')
        const transactionChargeType = _get(entry, 'arTransactionChargeType', '')
        const amount = _get(entry, 'amount', 0)
        const reason = _get(entry, 'reason', '')
        const createdBy = _get(entry, 'createdByIdentityInformation.name', '')
        const createdDate = parseDate(_get(entry, 'createdDate', ''), dateFormat)
        const postedBy = _get(entry, 'postedByIdentityInformation.name', '')
        const status = _get(entry, 'status', '')

        return {
          transactionChargeType: transactionChargeType,
          amount: formatter.format(amount),
          reason: reason,
          createdBy: createdBy,
          createDate: createdDate,
          status: status,
          postedBy: postedBy,
          actions: {
            component: WaiverActions,
            props: {
              reason: reason,
              waiver: entry,
              waiverRequestID: waiverRequestID,
              loadUpdateWaiver: _waiver => {
                this.selectedWaiver = _waiver
                this.toggle = true
              },

              confirmWaiverDelete: (_reason, _waiverRequestID) => {
                this.$buefy.dialog.confirm({
                  title: 'Deleting Waiver Fee Request',
                  message: `Are you sure you want to <b>delete</b> this Waiver Fee Request, '${_reason}'?`,
                  confirmText: 'Delete Waiver Fee Request',
                  type: 'is-danger',
                  hasIcon: true,
                  onConfirm: () => this.deleteWaiver(_waiverRequestID)
                })
              }
            }
          }
        }
      })

      this.rows = rows
    },

    addWaiver() {
      ;(this.selectedWaiver = null), (this.toggle = true)
    },

    async loadWaiver() {
      if (this.$route.query.arTransactionID && this.$route.query.arTransactionID != undefined) {
        this.toggle = true
        this.arTransactionID = this.$route.query.arTransactionID
      }

      if (this.isDebug == true) console.debug('in loadWaiver()...')
      await waiverList
        .dispatch('getWaiverList', {
          ownerID: this.ownerID
        })
        .then(({ list }) => {
          this.determineRows(list)
        })
    },

    async deleteWaiver(id) {
      if (this.isDebug == true) console.debug('deleteWaiver id=' + id)

      await waiverList.dispatch('deleteWaiver', {
        waiverRequestID: id,
        done: () => {
          this.loading = false
          this.reload()
        }
      })
    },

    reload() {
      this.loading = true
      this.rows = []
      if (this.unit && this.unit != undefined) {
        this.unitID = this.unit.unitID
      }

      this.loadWaiver()
      this.loading = false
    }
  },

  computed: {
    ...mapState({
      unit: state => state.hoa.unit,
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    })
  },

  watch: {
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  mounted() {
    if (this.authUser && this.authUser != undefined) {
      this.determineOwnerID()

      if (this.isDebug == true) console.debug('this.ownerID=' + this.ownerID)
    }
    this.reload()
  },

  i18n: {
    messages: {
      en: { waiver: { title: 'Waiver Fee Requests' } }
    }
  }
}
</script>

<style></style>
